import * as React from 'react';
import DefaultLayout from '../layouts/header';
import Footer from '../layouts/footer';
import '../styles/_bootstrap.scss'
import './index.scss';

class Partners extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <>
                <DefaultLayout />
                <div className="page">
                    <div className="container-fluid hero">
                        <section className="section platformPartners hero-big hero-with-image background-gradient text-left">
                            <div className="hero-no-image  container">
                                <div className="col-sm-6 col-xs-12 padding-none mark-text" >
                                    <h1 className="padding-bottom-M">The Channel Platform</h1>
                                    <p className="text-XL">DirekTek is proud to have formed strong relationships with a number of leading partners to help us offer a cutting-edge service to our brands and customers.</p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="container-fluid padding-none body">
                        <section className="section platform-partners padding-bottom-M">
                            <div className="container">
                                <h5 style={{ textTransform: "uppercase" }}><b>Integrations</b></h5>
                                <div className="border-top">
                                    <div className="partners-list padding-top-S">
                                        <a href="https://www.blujaysolutions.com/"><img alt="Blu Jay Solutions" className="partners-logo MD-logo" src="https://www.direktek.co.uk/images/blujay.jpg" /></a>
                                        <a href="http://www.virtualstock.co.uk/"><img alt="Virtual Stock" className="SM-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/virtualstock.png" /></a>
                                        <a href="https://www.netedi.co.uk/"><img alt="NetEDI" className="MD-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/netedi.png" /></a>
                                        <a href="http://www.metapack.com/"><img alt="MetaPack" className="MD-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/metapack.gif" /></a>
                                        <a href="https://www.stockinthechannel.co.uk/"><img alt="Stock In The Channel" className="XS-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/sitch.png" /></a>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="section platform-partners padding-bottom-M">
                            <div className="container">
                                <h5 style={{ textTransform: "uppercase" }}><b>Data Sharing</b></h5>
                                <div className="border-top">
                                    <div className="partners-list padding-top-S">
                                        <a href="https://www.direktek.co.uk/www.gfk.com"><img alt="GFK" className="XL-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/gfk.jpg" /></a>
                                        <a href="https://www.contextworld.com"><img alt="Context Worldwide" className="XL-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/context.png" /></a>
                                        <a href="https://www.futuresource-consulting.com"><img alt="Futuresoucre Consulting" className="SM-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/fsc.jpg" /></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                        <section className="section platform-partners padding-bottom-XL">
                            <div className="container">
                                <h5 style={{ textTransform: "uppercase" }}><b>Shipping</b></h5>
                                <div className="border-top">
                                    <div className="partners-list padding-top-S">
                                        <a href="https://www.ups.com"><img alt="UPS" className="LG-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/ups.png" /></a>
                                        <a href="https://www.tnt.com"><img alt="TNT" className="partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/tnt.png" /></a>
                                        <a href="https://www.dxdelivery.com/"><img alt="DX" className="partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/dx.png" /></a>
                                        <a href="http://www.geodis.co.uk"><img alt="Geodis" className="partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/geodis.png" /></a>
                                        <a href="http://www.yodel.co.uk/"><img alt="Yodel" className="partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/yodel.jpg" /></a>
                                        <a href="http://www.royalmail.com/"><img alt="Royal Mail" className="MD-logo partners-logo" src="https://assets.direktek.co.uk/images/partner-logos/royalmail.png" /></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
             </>
                    );
                }
            }
            
            
export default Partners; 